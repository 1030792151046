import ui2Plugin from '@mop/ui2/config/ui2Plugin';

export default defineNuxtPlugin((nuxtApp) => {
  const app = nuxtApp.vueApp.$nuxt;
  if (!app.$mopI18n) {
    return;
  }

  // bridge to ui2 components localisation, config etc
  nuxtApp.vueApp.use(ui2Plugin, {
    // locale: {
    //   t: app.$mopI18n.t,
    // },
    translations: {
      fileUpload: {
        click_to_upload: app.$mopI18n.t('ui.fileUpload.click_to_upload'),
      },
    },
  });
});

import type { AxiosError, AxiosInstance } from 'axios';
import type { StatusResponseData } from '@/types/status';
import { handleAxiosError, errorHandler } from '@/api/utils';
const API_NAME = 'AWS - B2B Registration';

export function sendRegistration(apiInstance: AxiosInstance, params: any): Promise<StatusResponseData> {
  return errorHandler<StatusResponseData>(async () => ({
    data: (await apiInstance.post('/b2b-registration', params)).data,
  })).catch((error: AxiosError) => {
    if ((error.response as StatusResponseData)?.data?.result) {
      return {
        data: (error.response as StatusResponseData).data,
      };
    }
    return {
      error: handleAxiosError(error, {
        api: API_NAME,
        method: 'sendRegistration',
        params,
      }),
    };
  });
}
export function completeRegistration(apiInstance: AxiosInstance, params: any, id: string): Promise<StatusResponseData> {
  return errorHandler<StatusResponseData>(async () => ({
    data: (await apiInstance.patch(`/b2b-registration/${id}`, params)).data,
  })).catch((error: AxiosError) => {
    if ((error.response as StatusResponseData)?.data?.result) {
      return {
        data: (error.response as StatusResponseData).data,
      };
    }
    return {
      error: handleAxiosError(error, {
        api: API_NAME,
        method: 'completeRegistration',
        params,
      }),
    };
  });
}

export function getRegistration(apiInstance: AxiosInstance, id: string): Promise<StatusResponseData> {
  return errorHandler<StatusResponseData>(async () => ({
    data: (await apiInstance.get(`/b2b-registration/${id}`)).data,
  })).catch((error: AxiosError) => {
    if ((error.response as StatusResponseData)?.data?.result) {
      return {
        data: (error.response as StatusResponseData).data,
      };
    }
    return {
      error: handleAxiosError(error, {
        api: API_NAME,
        method: 'getRegistration',
        params: id,
      }),
    };
  });
}
export function getSalesAgents(apiInstance: AxiosInstance): Promise<StatusResponseData> {
  return errorHandler<StatusResponseData>(async () => ({
    data: (await apiInstance.get(`/b2b-agents`)).data,
  })).catch((error: AxiosError) => {
    if ((error.response as StatusResponseData)?.data?.result) {
      return {
        data: (error.response as StatusResponseData).data,
      };
    }
    return {
      error: handleAxiosError(error, {
        api: API_NAME,
        method: 'getSalesAgents',
      }),
    };
  });
}

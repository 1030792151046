export default {
  breakpoint: {
    xs: { value: '0px' },
    sm: { value: '640px' },
    md: { value: '768px' },
    lg: { value: '1024px' },
    xl: { value: '1440px' },
    xxl: { value: '1920px' },
    xxxl: { value: '5000px' },
  },
  viewport: {
    tiny: { value: 'xs sm' },
    mobile: { value: 'sm md' },
    tablet: { value: 'md lg' },
    desktop: { value: 'lg xl' },
    big: { value: 'xl xxl' },
    infinite: { value: 'xxl xxxl' },
  },
};

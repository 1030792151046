import type { AxiosError, AxiosInstance } from 'axios';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { parseAxiosErrorResponseUrl } from '@mop/shared/utils/util';
import { logInfo } from '@mop/shared/utils/logger';
import type { APIAwsSetupParams } from '@/types';
import apiAws from '@/api/providers/aws';

const numberOfRetries = 1;
let apiAwsClient: AxiosInstance;

function retryCondition(error: AxiosError) {
  logInfo(`Retrying: ${parseAxiosErrorResponseUrl(error)}`);
  return true;
}

function getAwsClient(setupParams: APIAwsSetupParams) {
  if (!apiAwsClient) {
    apiAwsClient = axios.create({
      baseURL: setupParams.url,
      timeout: 30000,
      responseType: 'json',
      headers: {
        'x-api-key': setupParams.apiKey,
      },
    });

    axiosRetry(apiAwsClient, {
      retries: numberOfRetries,
      shouldResetTimeout: true,
      retryCondition,
    });
  }
  return apiAwsClient;
}

function createApiAws(setupParams: APIAwsSetupParams) {
  return apiAws(getAwsClient(setupParams));
}

export { createApiAws };

export const COOKIE: Record<string, string> = {
  DEBUG: 'debug',
  LANGUAGE: 'lang',
};

export const SCROLL_DIRECTION_UP = 'up';
export const SCROLL_DIRECTION_DOWN = 'down';

export const LOADING_DELAY = 500;

export default {
  ERROR_CODE: {
    NOT_FOUND: 404,
  },
  RUNTIME_ENVIRONMENT: {
    PRODUCTION: 'production',
    DEVELOPMENT: 'development',
  },
};

export const DIVISIONS = [
  { value: 'casualWomen', text: 'Casual Women' },
  { value: 'casualMen', text: 'Casual Men' },
  { value: 'denimWomen', text: 'Denim Women' },
  { value: 'denimMen', text: 'Denim Men' },
  { value: 'shoesWomen', text: 'Shoes Women' },
  { value: 'shoesMen', text: 'Shoes Men' },
  { value: 'accessoriesWomen', text: 'Accessories Women' },
  { value: 'accessoriesMen', text: 'Accessories Men' },
  { value: 'legwear', text: 'Legwear' },
];

export const CUSTOMER_TYPES = [
  { value: 'franchise', text: 'Franchise' },
  { value: 'multilabel', text: 'Multilabel' },
  { value: 'sis', text: 'SIS' },
  { value: 'distri', text: 'Distri' },
  { value: 'retail', text: 'Retail' },
  { value: 'online', text: 'Online' },
  { value: 'clearanceExternal', text: 'Clearance external' },
];

export const DISTRIBUTION_CHANNELS = [
  { value: 'wholesale', text: 'Wholesale' },
  { value: 'concession', text: 'Concession' },
  { value: 'distri', text: 'Distri' },
  { value: 'distriCustomer', text: 'Distri Customer' },
  { value: 'clearance', text: 'Clearance' },
];

export const COUNTRY_LISTS = {
  en: [
    { value: 'DE', text: 'Germany' },
    { value: 'AD', text: 'Andorra' },
    { value: 'AE', text: 'United Arab Emirates' },
    { value: 'AF', text: 'Afghanistan' },
    { value: 'AG', text: 'Antigua and Barbuda' },
    { value: 'AI', text: 'Anguilla' },
    { value: 'AL', text: 'Albania' },
    { value: 'AM', text: 'Armenia' },
    { value: 'AO', text: 'Angola' },
    { value: 'AQ', text: 'Antarctica' },
    { value: 'AR', text: 'Argentina' },
    { value: 'AS', text: 'American Samoa' },
    { value: 'AT', text: 'Austria' },
    { value: 'AU', text: 'Australia' },
    { value: 'AW', text: 'Aruba' },
    { value: 'AX', text: 'Åland Islands' },
    { value: 'AZ', text: 'Azerbaijan' },
    { value: 'BA', text: 'Bosnia and Herzegovina' },
    { value: 'BB', text: 'Barbados' },
    { value: 'BD', text: 'Bangladesh' },
    { value: 'BE', text: 'Belgium' },
    { value: 'BF', text: 'Burkina Faso' },
    { value: 'BG', text: 'Bulgaria' },
    { value: 'BH', text: 'Bahrain' },
    { value: 'BI', text: 'Burundi' },
    { value: 'BJ', text: 'Benin' },
    { value: 'BL', text: 'Saint Barthélemy' },
    { value: 'BM', text: 'Bermuda' },
    { value: 'BN', text: 'Brunei Darussalam' },
    { value: 'BO', text: 'Bolivia' },
    { value: 'BQ', text: 'Bonaire, Sint Eustatius and Saba' },
    { value: 'BR', text: 'Brazil' },
    { value: 'BS', text: 'Bahamas' },
    { value: 'BT', text: 'Bhutan' },
    { value: 'BV', text: 'Bouvet Island' },
    { value: 'BW', text: 'Botswana' },
    { value: 'BY', text: 'Belarus' },
    { value: 'BZ', text: 'Belize' },
    { value: 'CA', text: 'Canada' },
    { value: 'CC', text: 'Cocos (Keeling) Islands' },
    { value: 'CD', text: 'Congo, Democratic Republic' },
    { value: 'CF', text: 'Central African Republic' },
    { value: 'CG', text: 'Congo' },
    { value: 'CH', text: 'Switzerland' },
    { value: 'CI', text: "Côte d'Ivoire" },
    { value: 'CK', text: 'Cook Islands' },
    { value: 'CL', text: 'Chile' },
    { value: 'CM', text: 'Cameroon' },
    { value: 'CN', text: 'China' },
    { value: 'CO', text: 'Colombia' },
    { value: 'CR', text: 'Costa Rica' },
    { value: 'CU', text: 'Cuba' },
    { value: 'CV', text: 'Cabo Verde' },
    { value: 'CW', text: 'Curaçao' },
    { value: 'CX', text: 'Christmas Island' },
    { value: 'CY', text: 'Cyprus' },
    { value: 'CZ', text: 'Czech Republic' },
    { value: 'DJ', text: 'Djibouti' },
    { value: 'DK', text: 'Denmark' },
    { value: 'DM', text: 'Dominica' },
    { value: 'DO', text: 'Dominican Republic' },
    { value: 'DZ', text: 'Algeria' },
    { value: 'EC', text: 'Ecuador' },
    { value: 'EE', text: 'Estonia' },
    { value: 'EG', text: 'Egypt' },
    { value: 'EH', text: 'Western Sahara' },
    { value: 'ER', text: 'Eritrea' },
    { value: 'ES', text: 'Spain' },
    { value: 'ET', text: 'Ethiopia' },
    { value: 'FI', text: 'Finland' },
    { value: 'FJ', text: 'Fiji' },
    { value: 'FK', text: 'Falkland Islands (Malvinas)' },
    { value: 'FM', text: 'Micronesia, Federated States of' },
    { value: 'FO', text: 'Faroe Islands' },
    { value: 'FR', text: 'France' },
    { value: 'GA', text: 'Gabon' },
    { value: 'GB', text: 'United Kingdom' },
    { value: 'GD', text: 'Grenada' },
    { value: 'GE', text: 'Georgia' },
    { value: 'GF', text: 'French Guiana' },
    { value: 'GG', text: 'Guernsey' },
    { value: 'GH', text: 'Ghana' },
    { value: 'GI', text: 'Gibraltar' },
    { value: 'GL', text: 'Greenland' },
    { value: 'GM', text: 'Gambia' },
    { value: 'GN', text: 'Guinea' },
    { value: 'GP', text: 'Guadeloupe' },
    { value: 'GQ', text: 'Equatorial Guinea' },
    { value: 'GR', text: 'Greece' },
    { value: 'GS', text: 'South Georgia and the South Sandwich Islands' },
    { value: 'GT', text: 'Guatemala' },
    { value: 'GU', text: 'Guam' },
    { value: 'GW', text: 'Guinea-Bissau' },
    { value: 'GY', text: 'Guyana' },
    { value: 'HK', text: 'Hong Kong' },
    { value: 'HM', text: 'Heard Island and McDonald Islands' },
    { value: 'HN', text: 'Honduras' },
    { value: 'HR', text: 'Croatia' },
    { value: 'HT', text: 'Haiti' },
    { value: 'HU', text: 'Hungary' },
    { value: 'ID', text: 'Indonesia' },
    { value: 'IE', text: 'Ireland' },
    { value: 'IL', text: 'Israel' },
    { value: 'IM', text: 'Isle of Man' },
    { value: 'IN', text: 'India' },
    { value: 'IO', text: 'British Indian Ocean Territory' },
    { value: 'IQ', text: 'Iraq' },
    { value: 'IR', text: 'Iran, Islamic Republic of' },
    { value: 'IS', text: 'Iceland' },
    { value: 'IT', text: 'Italy' },
    { value: 'JE', text: 'Jersey' },
    { value: 'JM', text: 'Jamaica' },
    { value: 'JO', text: 'Jordan' },
    { value: 'JP', text: 'Japan' },
    { value: 'KE', text: 'Kenya' },
    { value: 'KG', text: 'Kyrgyzstan' },
    { value: 'KH', text: 'Cambodia' },
    { value: 'KI', text: 'Kiribati' },
    { value: 'KM', text: 'Comoros' },
    { value: 'KN', text: 'Saint Kitts and Nevis' },
    { value: 'KP', text: "Korea (Democratic People's Republic of)" },
    { value: 'KR', text: 'Korea, Republic of' },
    { value: 'KW', text: 'Kuwait' },
    { value: 'KY', text: 'Cayman Islands' },
    { value: 'KZ', text: 'Kazakhstan' },
    { value: 'LA', text: "Lao People's Democratic Republic" },
    { value: 'LB', text: 'Lebanon' },
    { value: 'LC', text: 'Saint Lucia' },
    { value: 'LI', text: 'Liechtenstein' },
    { value: 'LK', text: 'Sri Lanka' },
    { value: 'LR', text: 'Liberia' },
    { value: 'LS', text: 'Lesotho' },
    { value: 'LT', text: 'Lithuania' },
    { value: 'LU', text: 'Luxembourg' },
    { value: 'LV', text: 'Latvia' },
    { value: 'LY', text: 'Libya' },
    { value: 'MA', text: 'Morocco' },
    { value: 'MC', text: 'Monaco' },
    { value: 'MD', text: 'Moldova, Republic of' },
    { value: 'ME', text: 'Montenegro' },
    { value: 'MF', text: 'Saint Martin' },
    { value: 'MG', text: 'Madagascar' },
    { value: 'MH', text: 'Marshall Islands' },
    { value: 'MK', text: 'North Macedonia' },
    { value: 'ML', text: 'Mali' },
    { value: 'MM', text: 'Myanmar' },
    { value: 'MN', text: 'Mongolia' },
    { value: 'MO', text: 'Macao' },
    { value: 'MP', text: 'Northern Mariana Islands' },
    { value: 'MQ', text: 'Martinique' },
    { value: 'MR', text: 'Mauritania' },
    { value: 'MS', text: 'Montserrat' },
    { value: 'MT', text: 'Malta' },
    { value: 'MU', text: 'Mauritius' },
    { value: 'MV', text: 'Maldives' },
    { value: 'MW', text: 'Malawi' },
    { value: 'MX', text: 'Mexico' },
    { value: 'MY', text: 'Malaysia' },
    { value: 'MZ', text: 'Mozambique' },
    { value: 'NA', text: 'Namibia' },
    { value: 'NC', text: 'New Caledonia' },
    { value: 'NE', text: 'Niger' },
    { value: 'NF', text: 'Norfolk Island' },
    { value: 'NG', text: 'Nigeria' },
    { value: 'NI', text: 'Nicaragua' },
    { value: 'NL', text: 'Netherlands' },
    { value: 'NO', text: 'Norway' },
    { value: 'NP', text: 'Nepal' },
    { value: 'NR', text: 'Nauru' },
    { value: 'NU', text: 'Niue' },
    { value: 'NZ', text: 'New Zealand' },
    { value: 'OM', text: 'Oman' },
    { value: 'PA', text: 'Panama' },
    { value: 'PE', text: 'Peru' },
    { value: 'PF', text: 'French Polynesia' },
    { value: 'PG', text: 'Papua New Guinea' },
    { value: 'PH', text: 'Philippines' },
    { value: 'PK', text: 'Pakistan' },
    { value: 'PL', text: 'Poland' },
    { value: 'PM', text: 'Saint Pierre and Miquelon' },
    { value: 'PN', text: 'Pitcairn' },
    { value: 'PR', text: 'Puerto Rico' },
    { value: 'PS', text: 'Palestine, State of' },
    { value: 'PT', text: 'Portugal' },
    { value: 'PW', text: 'Palau' },
    { value: 'PY', text: 'Paraguay' },
    { value: 'QA', text: 'Qatar' },
    { value: 'RE', text: 'Réunion' },
    { value: 'RO', text: 'Romania' },
    { value: 'RS', text: 'Serbia' },
    { value: 'RU', text: 'Russian Federation' },
    { value: 'RW', text: 'Rwanda' },
    { value: 'SA', text: 'Saudi Arabia' },
    { value: 'SB', text: 'Solomon Islands' },
    { value: 'SC', text: 'Seychelles' },
    { value: 'SD', text: 'Sudan' },
    { value: 'SE', text: 'Sweden' },
    { value: 'SG', text: 'Singapore' },
    { value: 'SH', text: 'Saint Helena, Ascension and Tristan da Cunha' },
    { value: 'SI', text: 'Slovenia' },
    { value: 'SJ', text: 'Svalbard and Jan Mayen' },
    { value: 'SK', text: 'Slovakia' },
    { value: 'SL', text: 'Sierra Leone' },
    { value: 'SM', text: 'San Marino' },
    { value: 'SN', text: 'Senegal' },
    { value: 'SO', text: 'Somalia' },
    { value: 'SR', text: 'Suritext' },
    { value: 'SS', text: 'South Sudan' },
    { value: 'ST', text: 'Sao Tome and Principe' },
    { value: 'SV', text: 'El Salvador' },
    { value: 'SX', text: 'Sint Maarten, Dutch part' },
    { value: 'SY', text: 'Syrian Arab Republic' },
    { value: 'SZ', text: 'Eswatini' },
    { value: 'TC', text: 'Turks and Caicos Islands' },
    { value: 'TD', text: 'Chad' },
    { value: 'TF', text: 'French Southern Territories' },
    { value: 'TG', text: 'Togo' },
    { value: 'TH', text: 'Thailand' },
    { value: 'TJ', text: 'Tajikistan' },
    { value: 'TK', text: 'Tokelau' },
    { value: 'TL', text: 'Timor-Leste' },
    { value: 'TM', text: 'Turkmenistan' },
    { value: 'TN', text: 'Tunisia' },
    { value: 'TO', text: 'Tonga' },
    { value: 'TR', text: 'Türkiye' },
    { value: 'TT', text: 'Trinidad and Tobago' },
    { value: 'TV', text: 'Tuvalu' },
    { value: 'TW', text: 'Taiwan, Province of China' },
    { value: 'TZ', text: 'Tanzania, United Republic of' },
    { value: 'UA', text: 'Ukraine' },
    { value: 'UG', text: 'Uganda' },
    { value: 'UM', text: 'United States Minor Outlying Islands' },
    { value: 'US', text: 'United States of America' },
    { value: 'UY', text: 'Uruguay' },
    { value: 'UZ', text: 'Uzbekistan' },
    { value: 'VA', text: 'Holy See (Vatican City State)' },
    { value: 'VC', text: 'Saint Vincent and the Grenadines' },
    { value: 'VE', text: 'Venezuela, Bolivarian Republic of' },
    { value: 'VG', text: 'Virgin Islands, British' },
    { value: 'VI', text: 'Virgin Islands, U.S.' },
    { value: 'VN', text: 'Vietnam' },
    { value: 'VU', text: 'Vanuatu' },
    { value: 'WF', text: 'Wallis and Futuna' },
    { value: 'WS', text: 'Samoa' },
    { value: 'YE', text: 'Yemen' },
    { value: 'YT', text: 'Mayotte' },
    { value: 'ZA', text: 'South Africa' },
    { value: 'ZM', text: 'Zambia' },
    { value: 'ZW', text: 'Zimbabwe' },
  ],
  de: [
    { value: 'DE', text: 'Deutschland' },
    { value: 'AD', text: 'Andorra' },
    { value: 'AE', text: 'Vereinigte Arabische Emirate' },
    { value: 'AF', text: 'Afghanistan' },
    { value: 'AG', text: 'Antigua und Barbuda' },
    { value: 'AI', text: 'Anguilla' },
    { value: 'AL', text: 'Albanien' },
    { value: 'AM', text: 'Armenien' },
    { value: 'AO', text: 'Angola' },
    { value: 'AQ', text: 'Antarktis' },
    { value: 'AR', text: 'Argentinien' },
    { value: 'AS', text: 'Amerikanisch-Samoa' },
    { value: 'AT', text: 'Österreich' },
    { value: 'AU', text: 'Australien' },
    { value: 'AW', text: 'Aruba' },
    { value: 'AX', text: 'Åland-Inseln' },
    { value: 'AZ', text: 'Aserbaidschan' },
    { value: 'BA', text: 'Bosnien und Herzegowina' },
    { value: 'BB', text: 'Barbados' },
    { value: 'BD', text: 'Bangladesch' },
    { value: 'BE', text: 'Belgien' },
    { value: 'BF', text: 'Burkina Faso' },
    { value: 'BG', text: 'Bulgarien' },
    { value: 'BH', text: 'Bahrain' },
    { value: 'BI', text: 'Burundi' },
    { value: 'BJ', text: 'Benin' },
    { value: 'BL', text: 'Saint-Barthélemy' },
    { value: 'BM', text: 'Bermuda' },
    { value: 'BN', text: 'Brunei Darussalam' },
    { value: 'BO', text: 'Bolivien' },
    { value: 'BQ', text: 'Bonaire, Sint Eustatius und Saba' },
    { value: 'BR', text: 'Brasilien' },
    { value: 'BS', text: 'Bahamas' },
    { value: 'BT', text: 'Bhutan' },
    { value: 'BV', text: 'Bouvetinsel' },
    { value: 'BW', text: 'Botswana' },
    { value: 'BY', text: 'Weißrussland' },
    { value: 'BZ', text: 'Belize' },
    { value: 'CA', text: 'Kanada' },
    { value: 'CC', text: 'Kokosinseln' },
    { value: 'CD', text: 'Kongo, Demokratische Republik' },
    { value: 'CF', text: 'Zentralafrikanische Republik' },
    { value: 'CG', text: 'Kongo' },
    { value: 'CH', text: 'Schweiz' },
    { value: 'CI', text: 'Elfenbeinküste' },
    { value: 'CK', text: 'Cookinseln' },
    { value: 'CL', text: 'Chile' },
    { value: 'CM', text: 'Kamerun' },
    { value: 'CN', text: 'China' },
    { value: 'CO', text: 'Kolumbien' },
    { value: 'CR', text: 'Costa Rica' },
    { value: 'CU', text: 'Kuba' },
    { value: 'CV', text: 'Cabo Verde' },
    { value: 'CW', text: 'Curaçao' },
    { value: 'CX', text: 'Weihnachtsinsel' },
    { value: 'CY', text: 'Zypern' },
    { value: 'CZ', text: 'Tschechische Republik' },
    { value: 'DJ', text: 'Dschibuti' },
    { value: 'DK', text: 'Dänemark' },
    { value: 'DM', text: 'Dominica' },
    { value: 'DO', text: 'Dominikanische Republik' },
    { value: 'DZ', text: 'Algerien' },
    { value: 'EC', text: 'Ecuador' },
    { value: 'EE', text: 'Estland' },
    { value: 'EG', text: 'Ägypten' },
    { value: 'EH', text: 'Westsahara' },
    { value: 'ER', text: 'Eritrea' },
    { value: 'ES', text: 'Spanien' },
    { value: 'ET', text: 'Äthiopien' },
    { value: 'FI', text: 'Finnland' },
    { value: 'FJ', text: 'Fidschi' },
    { value: 'FK', text: 'Falklandinseln (Malvinen)' },
    { value: 'FM', text: 'Mikronesien, Föderierte Staaten von' },
    { value: 'FO', text: 'Färöer-Inseln' },
    { value: 'FR', text: 'Frankreich' },
    { value: 'GA', text: 'Gabun' },
    { value: 'GB', text: 'Vereinigtes Königreich' },
    { value: 'GD', text: 'Grenada' },
    { value: 'GE', text: 'Georgien' },
    { value: 'GF', text: 'Französisch-Guayana' },
    { value: 'GG', text: 'Guernsey' },
    { value: 'GH', text: 'Ghana' },
    { value: 'GI', text: 'Gibraltar' },
    { value: 'GL', text: 'Grönland' },
    { value: 'GM', text: 'Gambia' },
    { value: 'GN', text: 'Guinea' },
    { value: 'GP', text: 'Guadeloupe' },
    { value: 'GQ', text: 'Äquatorialguinea' },
    { value: 'GR', text: 'Griechenland' },
    { value: 'GS', text: 'Südgeorgien und die Südlichen Sandwichinseln' },
    { value: 'GT', text: 'Guatemala' },
    { value: 'GU', text: 'Guam' },
    { value: 'GW', text: 'Guinea-Bissau' },
    { value: 'GY', text: 'Guyana' },
    { value: 'HK', text: 'Hongkong' },
    { value: 'HM', text: 'Heard und McDonaldinseln' },
    { value: 'HN', text: 'Honduras' },
    { value: 'HR', text: 'Kroatien' },
    { value: 'HT', text: 'Haiti' },
    { value: 'HU', text: 'Ungarn' },
    { value: 'ID', text: 'Indonesien' },
    { value: 'IE', text: 'Irland' },
    { value: 'IL', text: 'Israel' },
    { value: 'IM', text: 'Isle of Man' },
    { value: 'IN', text: 'Indien' },
    { value: 'IO', text: 'Britisches Territorium im Indischen Ozean' },
    { value: 'IQ', text: 'Irak' },
    { value: 'IR', text: 'Iran, Islamische Republik' },
    { value: 'IS', text: 'Island' },
    { value: 'IT', text: 'Italien' },
    { value: 'JE', text: 'Jersey' },
    { value: 'JM', text: 'Jamaika' },
    { value: 'JO', text: 'Jordanien' },
    { value: 'JP', text: 'Japan' },
    { value: 'KE', text: 'Kenia' },
    { value: 'KG', text: 'Kirgisistan' },
    { value: 'KH', text: 'Kambodscha' },
    { value: 'KI', text: 'Kiribati' },
    { value: 'KM', text: 'Komoren' },
    { value: 'KN', text: 'St. Kitts und Nevis' },
    { value: 'KP', text: 'Korea (Demokratische Volksrepublik)' },
    { value: 'KR', text: 'Korea, Republik' },
    { value: 'KW', text: 'Kuwait' },
    { value: 'KY', text: 'Kaimaninseln' },
    { value: 'KZ', text: 'Kasachstan' },
    { value: 'LA', text: 'Laos, Demokratische Volksrepublik' },
    { value: 'LB', text: 'Libanon' },
    { value: 'LC', text: 'St. Lucia' },
    { value: 'LI', text: 'Liechtenstein' },
    { value: 'LK', text: 'Sri Lanka' },
    { value: 'LR', text: 'Liberia' },
    { value: 'LS', text: 'Lesotho' },
    { value: 'LT', text: 'Litauen' },
    { value: 'LU', text: 'Luxemburg' },
    { value: 'LV', text: 'Lettland' },
    { value: 'LY', text: 'Libyen' },
    { value: 'MA', text: 'Marokko' },
    { value: 'MC', text: 'Monaco' },
    { value: 'MD', text: 'Republik Moldau' },
    { value: 'ME', text: 'Montenegro' },
    { value: 'MF', text: 'St. Martin' },
    { value: 'MG', text: 'Madagaskar' },
    { value: 'MH', text: 'Marshallinseln' },
    { value: 'MK', text: 'Nordmazedonien' },
    { value: 'ML', text: 'Mali' },
    { value: 'MM', text: 'Myanmar' },
    { value: 'MN', text: 'Mongolei' },
    { value: 'MO', text: 'Macao' },
    { value: 'MP', text: 'Nördliche Marianen' },
    { value: 'MQ', text: 'Martinique' },
    { value: 'MR', text: 'Mauretanien' },
    { value: 'MS', text: 'Montserrat' },
    { value: 'MT', text: 'Malta' },
    { value: 'MU', text: 'Mauritius' },
    { value: 'MV', text: 'Malediven' },
    { value: 'MW', text: 'Malawi' },
    { value: 'MX', text: 'Mexiko' },
    { value: 'MY', text: 'Malaysia' },
    { value: 'MZ', text: 'Mosambik' },
    { value: 'NA', text: 'Namibia' },
    { value: 'NC', text: 'Neukaledonien' },
    { value: 'NE', text: 'Niger' },
    { value: 'NF', text: 'Norfolkinsel' },
    { value: 'NG', text: 'Nigeria' },
    { value: 'NI', text: 'Nicaragua' },
    { value: 'NL', text: 'Niederlande' },
    { value: 'NO', text: 'Norwegen' },
    { value: 'NP', text: 'Nepal' },
    { value: 'NR', text: 'Nauru' },
    { value: 'NU', text: 'Niue' },
    { value: 'NZ', text: 'Neuseeland' },
    { value: 'OM', text: 'Oman' },
    { value: 'PA', text: 'Panama' },
    { value: 'PE', text: 'Peru' },
    { value: 'PF', text: 'Französisch-Polynesien' },
    { value: 'PG', text: 'Papua-Neuguinea' },
    { value: 'PH', text: 'Philippinen' },
    { value: 'PK', text: 'Pakistan' },
    { value: 'PL', text: 'Polen' },
    { value: 'PM', text: 'St. Pierre und Miquelon' },
    { value: 'PN', text: 'Pitcairninseln' },
    { value: 'PR', text: 'Puerto Rico' },
    { value: 'PS', text: 'Palästina, Staat' },
    { value: 'PT', text: 'Portugal' },
    { value: 'PW', text: 'Palau' },
    { value: 'PY', text: 'Paraguay' },
    { value: 'QA', text: 'Katar' },
    { value: 'RE', text: 'Réunion' },
    { value: 'RO', text: 'Rumänien' },
    { value: 'RS', text: 'Serbien' },
    { value: 'RU', text: 'Russische Föderation' },
    { value: 'RW', text: 'Ruanda' },
    { value: 'SA', text: 'Saudi-Arabien' },
    { value: 'SB', text: 'Salomonen' },
    { value: 'SC', text: 'Seychellen' },
    { value: 'SD', text: 'Sudan' },
    { value: 'SE', text: 'Schweden' },
    { value: 'SG', text: 'Singapur' },
    { value: 'SH', text: 'St. Helena, Ascension und Tristan da Cunha' },
    { value: 'SI', text: 'Slowenien' },
    { value: 'SJ', text: 'Svalbard und Jan Mayen' },
    { value: 'SK', text: 'Slowakei' },
    { value: 'SL', text: 'Sierra Leone' },
    { value: 'SM', text: 'San Marino' },
    { value: 'SN', text: 'Senegal' },
    { value: 'SO', text: 'Somalia' },
    { value: 'SR', text: 'Suriname' },
    { value: 'SS', text: 'Südsudan' },
    { value: 'ST', text: 'São Tomé und Príncipe' },
    { value: 'SV', text: 'El Salvador' },
    { value: 'SX', text: 'Sint Maarten, niederländischer Teil' },
    { value: 'SY', text: 'Syrien' },
    { value: 'SZ', text: 'Eswatini' },
    { value: 'TC', text: 'Turks- und Caicosinseln' },
    { value: 'TD', text: 'Tschad' },
    { value: 'TF', text: 'Französische Süd- und Antarktisgebiete' },
    { value: 'TG', text: 'Togo' },
    { value: 'TH', text: 'Thailand' },
    { value: 'TJ', text: 'Tadschikistan' },
    { value: 'TK', text: 'Tokelau' },
    { value: 'TL', text: 'Timor-Leste' },
    { value: 'TM', text: 'Turkmenistan' },
    { value: 'TN', text: 'Tunesien' },
    { value: 'TO', text: 'Tonga' },
    { value: 'TR', text: 'Türkei' },
    { value: 'TT', text: 'Trinidad und Tobago' },
    { value: 'TV', text: 'Tuvalu' },
    { value: 'TW', text: 'Taiwan, Provinz China' },
    { value: 'TZ', text: 'Tansania, Vereinigte Republik' },
    { value: 'UA', text: 'Ukraine' },
    { value: 'UG', text: 'Uganda' },
    { value: 'UM', text: 'Amerikanisch-Ozeanien' },
    { value: 'US', text: 'Vereinigte Staaten von Amerika' },
    { value: 'UY', text: 'Uruguay' },
    { value: 'UZ', text: 'Usbekistan' },
    { value: 'VA', text: 'Heiliger Stuhl (Vatikanstadt)' },
    { value: 'VC', text: 'St. Vincent und die Grenadinen' },
    { value: 'VE', text: 'Venezuela' },
    { value: 'VG', text: 'Britische Jungferninseln' },
    { value: 'VI', text: 'Amerikanische Jungferninseln' },
    { value: 'VN', text: 'Vietnam' },
    { value: 'VU', text: 'Vanuatu' },
    { value: 'WF', text: 'Wallis und Futuna' },
    { value: 'WS', text: 'Samoa' },
    { value: 'YE', text: 'Jemen' },
    { value: 'YT', text: 'Mayotte' },
    { value: 'ZA', text: 'Südafrika' },
    { value: 'ZM', text: 'Sambia' },
    { value: 'ZW', text: 'Simbabwe' },
  ],
};

export function getCountryList(lang: string) {
  if (!['de', 'en'].includes(lang)) {
    lang = 'en';
  }
  const list = COUNTRY_LISTS[lang as 'de' | 'en'];
  // list.sort((a, b) => a.text.localeCompare(b.text));
  list.sort((a, b) => {
    if (a.value === 'DE') {
      return -1; // 'Germany' comes first
    } else if (b.value === 'DE') {
      return 1; // 'Germany' comes first
    } else {
      return a.text.localeCompare(b.text);
    }
  });
  return list as [
    {
      value: string;
      text: string;
    },
  ];
}

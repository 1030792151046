import { default as indexoFaQgDYBYCMeta } from "/opt/build/repo/apps/b2b-registration/pages/index.vue?macro=true";
import { default as PartnerRegistrationViewG6lu5xhVyhMeta } from "/views/PartnerRegistrationView.vue?macro=true";
import { default as CompleteRegistrationViewBFVoWZFQzpMeta } from "/views/CompleteRegistrationView.vue?macro=true";
import { default as FinishedRegistrationViewCZodUCOqYyMeta } from "/views/FinishedRegistrationView.vue?macro=true";
export default [
  {
    name: indexoFaQgDYBYCMeta?.name ?? "index",
    path: indexoFaQgDYBYCMeta?.path ?? "/",
    meta: indexoFaQgDYBYCMeta || {},
    alias: indexoFaQgDYBYCMeta?.alias || [],
    redirect: indexoFaQgDYBYCMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/apps/b2b-registration/pages/index.vue").then(m => m.default || m)
  },
  {
    name: PartnerRegistrationViewG6lu5xhVyhMeta?.name ?? "home",
    path: PartnerRegistrationViewG6lu5xhVyhMeta?.path ?? "/:lang(en|de)",
    meta: {...(PartnerRegistrationViewG6lu5xhVyhMeta || {}), ...{"cacheMaxAgeInSeconds":86400}},
    alias: PartnerRegistrationViewG6lu5xhVyhMeta?.alias || [],
    redirect: PartnerRegistrationViewG6lu5xhVyhMeta?.redirect || undefined,
    component: () => import("/views/PartnerRegistrationView.vue").then(m => m.default || m)
  },
  {
    name: CompleteRegistrationViewBFVoWZFQzpMeta?.name ?? "complete-registration",
    path: CompleteRegistrationViewBFVoWZFQzpMeta?.path ?? "/:lang(en|de)/complete-registration/:id",
    meta: {...(CompleteRegistrationViewBFVoWZFQzpMeta || {}), ...{"cacheMaxAgeInSeconds":86400}},
    alias: CompleteRegistrationViewBFVoWZFQzpMeta?.alias || [],
    redirect: CompleteRegistrationViewBFVoWZFQzpMeta?.redirect || undefined,
    component: () => import("/views/CompleteRegistrationView.vue").then(m => m.default || m)
  },
  {
    name: FinishedRegistrationViewCZodUCOqYyMeta?.name ?? "finished-registration",
    path: FinishedRegistrationViewCZodUCOqYyMeta?.path ?? "/:lang(en|de)/finished-registration/:id",
    meta: {...(FinishedRegistrationViewCZodUCOqYyMeta || {}), ...{"cacheMaxAgeInSeconds":86400}},
    alias: FinishedRegistrationViewCZodUCOqYyMeta?.alias || [],
    redirect: FinishedRegistrationViewCZodUCOqYyMeta?.redirect || undefined,
    component: () => import("/views/FinishedRegistrationView.vue").then(m => m.default || m)
  }
]
import { datadogRum } from '@datadog/browser-rum';

function CustomError(error: Error) {
  // @ts-ignore
  this.name = error?.name ?? 'CustomError';
  // @ts-ignore
  this.message = error?.message ?? 'CustomError';
  // @ts-ignore
  this.stack = error?.stack ?? '';
}

CustomError.prototype = Object.create(Error.prototype);

export default defineNuxtPlugin((nuxtApp) => {
  if (isPrerender() || isLinux()) {
    return;
  }
  const config = useRuntimeConfig();
  const applicationId = String(config.public.DATADOG_APP_KEY ?? '');
  const clientToken = String(config.public.DATADOG_TOKEN ?? '');
  if (!applicationId || !clientToken) {
    return;
  }
  const version = String(config.public.BUILD_ID ?? '');
  const productionHost = String(config.public.DATADOG_APPLICATION_NAME);

  datadogRum.init({
    applicationId,
    clientToken,
    env: config.public.RUNTIME_ENVIRONMENT,
    site: 'datadoghq.com',
    service: productionHost,
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  // @TODO: add startSessionReplayRecording() if needed and check if consent is needed

  window.mopLogger = {
    logError(error: any) {
      try {
        // @ts-ignore
        throw new CustomError(error);
      } catch (error) {
        datadogRum.addError(error);
      }
    },
  };

  nuxtApp.provide('datadog', datadogRum);
});

import { createApiAws } from '@/api';

export default defineNuxtPlugin((nuxtApp) => {
  const app = nuxtApp.vueApp.$nuxt;
  if (!app.$mopI18n) {
    return;
  }
  const config = useRuntimeConfig();
  nuxtApp.provide(
    'apiAws',
    createApiAws({
      url: config.public.AWS_URL,
      apiKey: config.public.AWS_API_KEY,
    }),
  );
});

import { defineNuxtPlugin } from '#app/nuxt'
import { LazyUi2Avatar, LazyUi2Badge, LazyUi2Breadcrumbs, LazyUi2Button, LazyUi2CheckBox, LazyUi2CompanyAudio, LazyUi2Datepicker, LazyUi2DotBadge, LazyUi2DropdownMenu, LazyUi2DropdownSelect, LazyUi2EcomCallout, LazyUi2EcomPopularityFlag, LazyUi2EcomPrice, LazyUi2EcomProductCard, LazyUi2FeaturedIcon, LazyUi2FileUpload, LazyUi2Form, LazyUi2Icon, LazyUi2Image, LazyUi2Loader, LazyUi2Notification, LazyUi2NuxtLink, LazyUi2Overlay, LazyUi2OverlayHeader, LazyUi2Pagination, LazyUi2Progress, LazyUi2Radio, LazyUi2RangeSlider, LazyUi2Tab, LazyUi2TabGroup, LazyUi2TabImage, LazyUi2TabPanel, LazyUi2Tag, LazyUi2TextFieldArea, LazyUi2TextFieldBirthday, LazyUi2TextFieldInput, LazyUi2TextFieldPassword, LazyUi2Toast, LazyUi2Toggle, LazyUi2Tooltip, LazyFormComplete, LazyFormFinished, LazyFormRegister, LazyFormSection, LazyFormSidebar, LazyFormCompleteSummary, LazyFormCompleteThankYou, LazyFormCompleteSectionAdditionalEntries, LazyFormCompleteSectionAdditionalEntriesStoreDivision, LazyFormFinishedSummary, LazyFormRegisterAddress, LazyFormRegisterSectionCompanyInformation, LazyFormRegisterSectionContactPerson, LazyFormRegisterSectionEdiInformation, LazyFormRegisterSectionInvoiceAddress, LazyFormRegisterSectionLoginForB2B, LazyFormRegisterSectionPaymentDetails, LazyFormRegisterSectionSalesAgent, LazyFormRegisterSectionStoreAddressList, LazyFormRegisterSectionStoreAddressListSummary, LazyFormRegisterStoreAddress, LazyFormRegisterStoreDeliveryAddress, LazyFormRegisterSummary, LazyFormRegisterThankYou, LazyMopFooter, LazyMopHeader } from '#components'
const lazyGlobalComponents = [
  ["Ui2Avatar", LazyUi2Avatar],
["Ui2Badge", LazyUi2Badge],
["Ui2Breadcrumbs", LazyUi2Breadcrumbs],
["Ui2Button", LazyUi2Button],
["Ui2CheckBox", LazyUi2CheckBox],
["Ui2CompanyAudio", LazyUi2CompanyAudio],
["Ui2Datepicker", LazyUi2Datepicker],
["Ui2DotBadge", LazyUi2DotBadge],
["Ui2DropdownMenu", LazyUi2DropdownMenu],
["Ui2DropdownSelect", LazyUi2DropdownSelect],
["Ui2EcomCallout", LazyUi2EcomCallout],
["Ui2EcomPopularityFlag", LazyUi2EcomPopularityFlag],
["Ui2EcomPrice", LazyUi2EcomPrice],
["Ui2EcomProductCard", LazyUi2EcomProductCard],
["Ui2FeaturedIcon", LazyUi2FeaturedIcon],
["Ui2FileUpload", LazyUi2FileUpload],
["Ui2Form", LazyUi2Form],
["Ui2Icon", LazyUi2Icon],
["Ui2Image", LazyUi2Image],
["Ui2Loader", LazyUi2Loader],
["Ui2Notification", LazyUi2Notification],
["Ui2NuxtLink", LazyUi2NuxtLink],
["Ui2Overlay", LazyUi2Overlay],
["Ui2OverlayHeader", LazyUi2OverlayHeader],
["Ui2Pagination", LazyUi2Pagination],
["Ui2Progress", LazyUi2Progress],
["Ui2Radio", LazyUi2Radio],
["Ui2RangeSlider", LazyUi2RangeSlider],
["Ui2Tab", LazyUi2Tab],
["Ui2TabGroup", LazyUi2TabGroup],
["Ui2TabImage", LazyUi2TabImage],
["Ui2TabPanel", LazyUi2TabPanel],
["Ui2Tag", LazyUi2Tag],
["Ui2TextFieldArea", LazyUi2TextFieldArea],
["Ui2TextFieldBirthday", LazyUi2TextFieldBirthday],
["Ui2TextFieldInput", LazyUi2TextFieldInput],
["Ui2TextFieldPassword", LazyUi2TextFieldPassword],
["Ui2Toast", LazyUi2Toast],
["Ui2Toggle", LazyUi2Toggle],
["Ui2Tooltip", LazyUi2Tooltip],
["FormComplete", LazyFormComplete],
["FormFinished", LazyFormFinished],
["FormRegister", LazyFormRegister],
["FormSection", LazyFormSection],
["FormSidebar", LazyFormSidebar],
["FormCompleteSummary", LazyFormCompleteSummary],
["FormCompleteThankYou", LazyFormCompleteThankYou],
["FormCompleteSectionAdditionalEntries", LazyFormCompleteSectionAdditionalEntries],
["FormCompleteSectionAdditionalEntriesStoreDivision", LazyFormCompleteSectionAdditionalEntriesStoreDivision],
["FormFinishedSummary", LazyFormFinishedSummary],
["FormRegisterAddress", LazyFormRegisterAddress],
["FormRegisterSectionCompanyInformation", LazyFormRegisterSectionCompanyInformation],
["FormRegisterSectionContactPerson", LazyFormRegisterSectionContactPerson],
["FormRegisterSectionEdiInformation", LazyFormRegisterSectionEdiInformation],
["FormRegisterSectionInvoiceAddress", LazyFormRegisterSectionInvoiceAddress],
["FormRegisterSectionLoginForB2B", LazyFormRegisterSectionLoginForB2B],
["FormRegisterSectionPaymentDetails", LazyFormRegisterSectionPaymentDetails],
["FormRegisterSectionSalesAgent", LazyFormRegisterSectionSalesAgent],
["FormRegisterSectionStoreAddressList", LazyFormRegisterSectionStoreAddressList],
["FormRegisterSectionStoreAddressListSummary", LazyFormRegisterSectionStoreAddressListSummary],
["FormRegisterStoreAddress", LazyFormRegisterStoreAddress],
["FormRegisterStoreDeliveryAddress", LazyFormRegisterStoreDeliveryAddress],
["FormRegisterSummary", LazyFormRegisterSummary],
["FormRegisterThankYou", LazyFormRegisterThankYou],
["MopFooter", LazyMopFooter],
["MopHeader", LazyMopHeader],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})

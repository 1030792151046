export function isLinux() {
  return /X11; Linux/i.test(navigator?.userAgent);
}

export function isPrerender() {
  return navigator?.userAgent?.toLowerCase().includes('prerender');
}

export const isHttpsEnabled: boolean = (window as any).__NUXT__.config.public.BASE_URL.startsWith('https://');

export const isProductionBuild: boolean =
  isHttpsEnabled &&
  (window as any).__NUXT__.config.public.RUNTIME_ENVIRONMENT === constants.RUNTIME_ENVIRONMENT.PRODUCTION;

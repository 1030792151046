import type { LocaleObject } from '@/types/locale';

export const localeList: LocaleObject[] = [
  {
    code: 'en',
    lang: 'en',
    alpha3: 'ENG',
  },
  {
    code: 'de',
    lang: 'de',
    alpha3: 'DEU',
  },
];

export const defaultLocale: string = localeList[0].code;

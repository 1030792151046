import type { AxiosInstance } from 'axios';
import { sendRegistration, getRegistration, completeRegistration, getSalesAgents } from './apiB2b';

export default function initApiAws(apiInstance: AxiosInstance) {
  return {
    sendRegistration: (params: any) => sendRegistration(apiInstance, params),
    completeRegistration: (params: any, id: string) => completeRegistration(apiInstance, params, id),
    getRegistration: (id: string) => getRegistration(apiInstance, id),
    getSalesAgents: () => getSalesAgents(apiInstance),
  };
}

export type ApiAws = ReturnType<typeof initApiAws>;

import { createI18n } from 'vue-i18n';

import { localeList, defaultLocale } from '@/i18n/localeList';
import type { LocaleObject, MopI18n } from '@/types/locale';

export default defineNuxtPlugin(async (nuxtApp) => {
  const { $cookie, $debug } = nuxtApp.vueApp.$nuxt;
  const route = useRoute();
  const router = useRouter();

  const localeFromRoute = route.path.split('/')[1];

  let locale: LocaleObject | undefined = localeList.find((config) => config.code === localeFromRoute) || localeList[0];
  const currentPath = router.currentRoute.value.path;
  if (!locale) {
    const redirectLocale: string = $cookie.get(COOKIE.LANGUAGE) || defaultLocale;
    const fullPath: string = route.path === '/' ? '' : route.path;
    $debug.log(
      `MopI18nPlugin > no locale found, cookie: ${$cookie.get(
        COOKIE.LANGUAGE,
      )}, redirectLocale: ${redirectLocale}, fullPath: ${fullPath}`,
    );
    locale = localeList.find((config) => config.code === redirectLocale);
    window.location.href = `/${redirectLocale}${fullPath}`;
    // Do not return, otherwise a lot of locale errors occure, before the redirect
    // return;
    // window.location.pathname === '/' || window.location.pathname === ''
  } else if (currentPath === '/' || currentPath === '') {
    window.location.href = `/${locale.code}`;
  }

  if (locale && $cookie.get(COOKIE.LANGUAGE) !== locale?.code) {
    $cookie.store(COOKIE.LANGUAGE, locale.code);
  }

  function formatDate(datetime: string, options: any) {
    const reducedTime: string = datetime.split('T')[0]?.replace(' ', 'T');
    const withoutYear: boolean = options?.withoutYear;
    const date: Date = new Date(Date.parse(reducedTime));
    const formattedDate = `${date.toLocaleDateString(locale!.code, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })}`;

    return withoutYear ? `${formattedDate.split('.')[0]}.${formattedDate.split('.')[1]}` : formattedDate;
  }

  const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: locale?.lang,
    messages: {
      // @ts-ignore
      [locale.lang]: await import(`@/i18n/locale/${locale?.lang}.json`),
    },
  });

  nuxtApp.vueApp.use(i18n);

  function localePath(route?: string): string {
    const localizedRoute = route
      ? useRouter().resolve(`/${locale?.lang}/${route}`)
      : useRouter().resolve(`/${locale?.lang}`);
    return localizedRoute ? localizedRoute.fullPath : '';
  }

  // @ts-ignore
  const { t, te, tm } = i18n.global;
  nuxtApp.provide('mopI18n', {
    locale: locale?.code,
    lang: locale?.lang,
    localePath,
    t,
    te,
    tm,
    alpha3: locale?.alpha3,
    localeList,
    formatDate,
  } as MopI18n);
});

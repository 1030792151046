export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"description","content":"Marc O’Polo B2B Partner Registration"},{"name":"apple-mobile-web-app-title","content":"Marc O’Polo"},{"name":"application-name","content":"Marc O’Polo"},{"name":"msapplication-TileColor","content":"#ffffff"},{"name":"theme-color","content":"#ffffff"},{"name":"robots","content":"noindex, nofollow"}],"link":[{"rel":"apple-touch-icon","sizes":"180x180","href":"/apple-touch-icon.png"},{"rel":"shortcut icon","href":"/favicon.ico"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon-32x32.png"},{"rel":"manifest","href":"/manifest.json"},{"rel":"mask-icon","href":"/safari-pinned-tab.svg"},{"rel":"preload","href":"/fonts/MOPGothic-Regular.woff2","as":"font","type":"font/woff2","crossorigin":"anonymous"},{"rel":"preload","href":"/fonts/MOPGothic-Bold.woff2","as":"font","type":"font/woff2","crossorigin":"anonymous"}],"style":[],"script":[],"noscript":[],"title":"MOP business partner registration"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'
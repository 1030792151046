import breakpoints from '@mop/ui2/tokens/breakpoint';
import type { Breakpoint, ViewportSize } from '@/types/breakpoint';

const isValueBetween: Function = (value: number, from: number, to: number) => value >= from && value < to;

export default defineNuxtPlugin((nuxtApp) => {
  const { breakpoint } = breakpoints;
  const currentViewportRef = ref<ViewportSize>('infinite');
  const xs = parseInt(breakpoint.xs.value);
  const sm = parseInt(breakpoint.sm.value);
  const md = parseInt(breakpoint.md.value);
  const lg = parseInt(breakpoint.lg.value);
  const xl = parseInt(breakpoint.xl.value);
  const xxl = parseInt(breakpoint.xxl.value);

  const {
    // @ts-ignore
    $resize: { viewportWidthRef },
  } = nuxtApp;
  watch(
    viewportWidthRef,
    () => {
      const width: number = viewportWidthRef.value;
      if (isValueBetween(width, xs, sm)) {
        currentViewportRef.value = 'tiny';
      } else if (isValueBetween(width, sm, md)) {
        currentViewportRef.value = 'mobile';
      } else if (isValueBetween(width, md, lg)) {
        currentViewportRef.value = 'tablet';
      } else if (isValueBetween(width, lg, xl)) {
        currentViewportRef.value = 'desktop';
      } else if (isValueBetween(width, xl, xxl)) {
        currentViewportRef.value = 'big';
      } else {
        currentViewportRef.value = 'infinite';
      }
    },
    { immediate: true },
  );

  const result: Breakpoint = {
    currentRef: currentViewportRef,
    isTinyRef: computed(() => currentViewportRef.value === 'tiny'),
    isMobileRef: computed(() => currentViewportRef.value === 'mobile'),
    isTabletRef: computed(() => currentViewportRef.value === 'tablet'),
    isDesktopRef: computed(() => currentViewportRef.value === 'desktop'),
    isBigRef: computed(() => currentViewportRef.value === 'big'),
    isInfiniteRef: computed(() => currentViewportRef.value === 'infinite'),
  };

  nuxtApp.provide('breakpoint', result);
});
